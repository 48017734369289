<template>
  <div
    class="flex flex-col-reverse items-center lg:flex-row justify-between lg:space-x-12"
  >
    <div class="flex flex-col space-y-6">
      <div class="flex flex-col space-y-2">
        <h2 class="text-2xl md:text-3xl font-bold">
          Hey there, I'm Saransh - <br />
          Web Developer, UI/UX Designer and a Learner.
        </h2>
        <p class="text-gray-400">
          I Enjoy creating things that live on the internet, whether that be
          websites, applications, or anything in between. I am a tech enthusiast
          so i have learned alot with internet and always upgrading with the
          technology.
        </p>
      </div>
      <a
        class="hover:underline w-max text-gray-400 hover:text-white transition-all duration-300"
        href="mailto:hellosaranshh@gmail.com"
        >hellosaranshh@gmail.com</a
      >
    </div>
    <img
      class="h-44 w-44 mb-12 lg:m-0 lg:max-w-full filter grayscale hover:filter-none transition duration-1000  rounded-md"
      src="https://github.com/5tupidbrain.png"
      alt=""
    />
  </div>
</template>
